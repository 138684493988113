.navbar {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3.8rem;
  height: 5rem;
  top: 0;
  width: 100%;
  background-color: #fbfcfc;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.05);
  z-index: 99;
  background-color: var(--background-color);
  color: var(--text-color);
}

.nav-list {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 2rem;
  font-size: 1.25rem;
  cursor: pointer;
  margin-right: 27rem;
}

.nav-name {
  font-size: 2.5rem;
  font-weight: 500;
  text-transform: uppercase;
  -webkit-text-stroke: 0.2rem var(--text-color);
  color: transparent;
  cursor: pointer;
}

.nav-link {
  position: relative;
  display: inline-block;
  text-decoration: none;
}

.nav-link::before,
.nav-link::after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  height: 0.15rem;
  background-color: #3498db;
  transform: scaleX(0);
  transition: transform 0.25s;
}

.nav-link::before {
  top: -0.2rem;
  transform-origin: left;
}

.nav-link::after {
  bottom: -0.2rem;
  transform-origin: right;
}

.nav-link:hover::before,
.nav-link:hover::after,
.active::before,
.active::after {
  transform: scaleX(1);
}

.mode-button {
  margin-left: -65rem;
  font-size: 1.5rem;
  transition: transform 0.2s;
  cursor: pointer;
}

.mode-button:hover {
  transform: scale(1.1);
}

.hamburger-menu {
  display: none;
  cursor: pointer;
}

.hamburger-menu .bar {
  display: block;
  width: 1.6rem;
  height: 0.2rem;
  margin: 0.3rem;
  background: var(--text-color);
  transition: all 0.3s ease-in-out;
}

@media (max-width: 992px) {
  .hamburger-menu {
    display: block;
  }

  .navbar {
    padding: 3rem;
  }
  .nav-name {
    font-size: 1.8rem;
    font-weight: 400;
    font-family: "Poppins", sans-serif;
  }

  .mode-button {
    margin-left: 28rem;
    font-size: 1.35rem;
  }

  .nav-list {
    position: fixed;
    left: -100%;
    top: 0;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5rem;
    width: 100%;
    font-size: 2.5rem;
    text-align: center;
    background-color: var(--background-color);
    color: var(--text-color);
    transition: all 0.3s ease-in-out;
  }

  .nav-list.active {
    left: 0;
  }

  /* Animation styles for hamburger menu */
  .hamburger-menu.active-menu .bar:nth-child(2) {
    opacity: 0;
  }

  .hamburger-menu.active-menu .bar:nth-child(1) {
    transform: translateY(8px) rotate(45deg);
  }

  .hamburger-menu.active-menu .bar:nth-child(3) {
    transform: translateY(-8px) rotate(-45deg);
  }
}

@media (max-width: 768px) {
  .navbar {
    padding: 1.5rem;
  }
  .nav-name {
    font-size: 1.5rem;
    -webkit-text-stroke: 0.1rem var(--text-color);
  }
  .nav-list {
    font-size: 1.6rem;
  }
  .mode-button {
    margin-left: 8rem;
    font-size: 1.25rem;
  }
}
