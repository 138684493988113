@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Playfair+Display:wght@500&family=Poppins:wght@300;400;500;600&display=swap");

:root {
  --background-color: #f9f9f9; /* Default light mode background color */
  --text-color: #333; /* Default text color in light mode */
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}

body {
  font-family: "Poppins", sans-serif;
  z-index: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
}

h2 {
  position: relative;
  font-size: 2.75rem;
  margin-top: 2.5rem;
  font-weight: 400;
}

h2::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0.3em;
  width: 100%;
  background-color: #d6eaf8;
  height: 0.3rem;
}

@media (max-width: 768px) {
  h2 {
    font-size: 1.75rem;
    margin-top: 1rem;
    font-weight: 400;
  }

  h2::after {
    bottom: 0.2em;
    height: 0.2rem;
  }
}
