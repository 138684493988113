/* Footer Styling */
.footer-section {
  background-color: #333;
  color: #fff;
  padding: 2rem;
  width: 100%;
  bottom: 0;
}

.footer-container {
  max-width: 60rem;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer-text {
  font-size: 1rem;
}

.footer-icons {
  display: flex;
  gap: 1.5rem;
}

.footer-icon {
  color: #fff;
  font-size: 2.25rem;
  transition: color 0.2s ease-in-out;
}

.footer-icon:hover {
  color: #999;
}

/* Media Queries */

/* Mobile Phones */
@media (max-width: 480px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .footer-icons {
    margin-top: 1rem;
  }
}

/* Tablets and iPads */
@media (min-width: 481px) and (max-width: 1024px) {
  .footer-container {
    flex-direction: column;
    align-items: center;
  }

  .footer-text {
    font-size: 0.9rem;
  }

  .footer-icons {
    margin-top: 1rem;
  }
}
