.home-container {
  width: 100%;
  height: 100vh;
  background-color: var(--background-color);
  color: var(--text-color);
  padding: 0 3rem;
  margin: 0 auto;
}

.home-content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6rem;
  height: 50rem;
  margin-bottom: 1rem;
}

h1 {
  font-size: 3.5rem;
  line-height: 1;
  font-weight: 600;
}

h4 {
  font-size: 1.75rem;
  font-weight: 400;
  position: relative;
  display: inline-block;
}

h4::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0.2em;
  width: 100%;
  height: 0.3rem;
  background-color: #d6eaf8;
}

span {
  display: block;
}

span a i {
  margin: 0.5rem;
  font-size: 2.25rem;
  color: var(--text-color);
  transition: color 0.3s ease-in-out; /* Smooth color transition on hover */
}

span a i:hover {
  color: #2f9fee;
}

.image-container {
  width: 30rem;
}

.my-image {
  max-width: 22rem;
  border-radius: 32% 68% 70% 30% / 39% 38% 62% 61%;
}

.skills-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -3rem;
}

.skills-container p {
  border-right: 2px solid var(--text-color);
  padding-right: 1.5rem;
  font-size: 1.5rem;
  font-weight: 400;
  margin-right: 2rem;
}

.skills-list {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
}

.skill {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 3.5rem;
  border-radius: 50%;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s;
  background-color: #f4f6f7;
  cursor: pointer;
}

.skill:hover {
  transform: scale(1.1);
}

.skill img {
  width: 2rem;
  height: 2rem;
}

/* Mobile Phones */
@media (max-width: 768px) {
  .home-container {
    padding: 0.5rem 1.5rem;
    height: auto;
  }
  .home-content {
    flex-direction: column-reverse;
    gap: 2rem;
    text-align: center;
    height: 42rem;
  }

  h1 {
    font-size: 2rem;
    font-weight: 500;
  }

  h4 {
    font-size: 1.25rem;
  }

  h4::after {
    height: 0.2rem;
  }
  .image-container {
    width: 100%;
  }

  .my-image {
    max-width: 70%;
    margin: 0 auto;
  }

  .skills-container {
    flex-direction: column;
    margin-top: -6.5rem;
    padding: 0.5rem;
  }

  .skills-container p {
    font-size: 1.25rem;
    border-bottom: 2px solid rgb(0, 0, 0, 0.3);
    border-right: none;
    padding-right: 0;
    margin-bottom: 1.25rem;
    margin-right: 0;
  }
}

/* Tablets */
@media (min-width: 769px) and (max-width: 992px) {
  .home-content {
    flex-direction: column-reverse;
    gap: 4rem;
    text-align: center;
    height: 60rem;
  }

  .image-container {
    width: 25rem;
  }

  .my-image {
    max-width: 75%;
    margin: 0 auto;
  }

  .skills-list {
    gap: 1rem;
  }
}
