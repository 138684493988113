.contact-container {
  width: 100%;
  margin: 0 auto;
  padding: 2rem;
  background-color: var(--background-color);
  color: var(--text-color);
}

.contact-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2rem;
}

.contact-list {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
}

.contact-list li {
  list-style: none;
  border-radius: 5px;
  font-weight: 500;
  font-size: 1.25rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  transition: 0.3s ease-in-out;
  padding: 0.5rem;
  text-align: center;
  width: 10%;
}

.contact-list li:hover {
  background-color: #2088e9;
  color: white;
}

.contact-list a {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  color: inherit;
}

.contact-list i {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}
.contact-list span {
  flex-grow: 1;
}

/* Media Query for smaller screens */

@media (max-width: 768px) {
  .contact-list {
    flex-direction: column;
    align-items: center;
  }

  .contact-list li {
    width: 50%;
    margin-bottom: 1rem;
  }
}

@media (min-width: 769px) and (max-width: 992px) {
  .contact-list li {
    width: 60%;
    margin-bottom: 1rem;
  }
}
