.about-container {
  padding: 4rem 4rem 0 4rem;
  background-color: var(--background-color);
  color: var(--text-color);
}

.about-heading {
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
}

.about-text {
  text-align: center;
}

.about-text p {
  font-size: 1.5rem;
  line-height: 2.2rem;
  font-weight: 400;
}

/* Media Queries */

/* Mobile Phones */
@media (max-width: 480px) {
  .about-container {
    padding: 4.5rem;
  }

  .about-text p {
    font-size: 1.2rem;
    line-height: 1.8rem;
    margin-bottom: 1.5rem;
  }
}

/* Tablets (iPad, etc.) */
@media (min-width: 481px) and (max-width: 768px) {
  .about-container {
    padding: 5rem;
  }

  .about-text p {
    font-size: 1.3rem;
    line-height: 2rem;
    margin-bottom: 2rem;
  }
}
