/* Container for the projects */
.project-container {
  padding: 4rem 4rem 0 4rem;
  max-width: fit-content;
  background-color: var(--background-color);
  color: var(--text-color);
}

.project-heading {
  display: flex;
  justify-content: center;
  margin-bottom: 3rem;
}

/* Projects container layout */
.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

/* Styling for each project card */
.project-card {
  background-color: #ffffff;
  color: #34495e;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  border-radius: 0.5rem;
  width: calc(33.33% - 2rem);
  margin-bottom: 2rem;
  padding: 0.25rem;
  box-sizing: border-box;
  position: relative;
}

/* Content within each project card */
.card-content {
  text-align: center;
}

/* Styling for the project thumbnail frame */
.project-thumbnail-frame {
  padding-top: 65%;
  position: relative;
}

/* Styling for project thumbnail images */
.project-thumbnail-frame img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

/* Details section within each project card */
.card-details {
  padding: 1rem 0 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Styling for project titles */
h5 {
  font-size: 2rem;
  font-weight: 500;
}

/* Technologies section within each project card */
.card-tech {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 80%;
  margin-bottom: 0.6rem;
}

/* Styling for each tech label */
.tech-skill {
  background-color: #cadef0;
  margin: 0.2rem;
  border-radius: 5px;
}

/* Styling for tech label text */
.tech-skill p {
  font-size: 0.8rem;
  padding: 0.2rem 0.3rem;
  color: #1d1818;
}

/* Description section within each project card */
.card-description {
  margin: 0 0.5rem 3rem;
}

/* Styling for description text */
.card-description p {
  font-weight: 500;
  font-size: 1rem;
  text-align: justify;
}

/* Buttons section within each project card */
.card-buttons {
  position: absolute; /* Position at the bottom of the card */
  bottom: 1rem;
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Center horizontally */
  display: flex;
  justify-content: space-around;
  width: 100%; /* Occupy full width of the card */
}

/* Styling for project buttons */
.card-buttons a {
  text-align: center;
  text-decoration: none;
  padding: 0.2rem;
  width: 7rem;
  font-weight: 400;
  font-size: 1rem;
  border-radius: 1rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  border: none; /* No border for a cleaner look */
  transition: 0.12s ease-in-out;
  background-color: #34495e;
  color: #ffffff;
}

/* Hover effect for project buttons */
.card-buttons a:hover {
  background-color: #ffffff;
  color: #34495e;
}

/* Media Queries for Responsive Design */

/* Mobile Phones */
@media (max-width: 768px) {
  .project-container {
    padding: 4rem 1rem 0 1rem;
  }
  .project-heading {
    margin-bottom: 0.8rem;
  }

  .card-container {
    justify-content: center;
  }

  .project-card {
    width: 100%;
    margin-top: 1rem;
  }
  h5 {
    font-size: 1.5rem;
  }
}

/* Tablets */
@media (min-width: 769px) and (max-width: 992px) {
  .project-container {
    padding: 4rem 1rem 0 1rem;
  }
  .project-heading {
    margin-bottom: 1rem;
  }
  .card-container {
    justify-content: center;
  }

  .project-card {
    width: 100%;
    margin-top: 2rem;
  }

  h5 {
    font-size: 1.65rem;
  }
}
